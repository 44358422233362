<template>
  <div>
    <div v-if="loading">
      <div class="flex col-12 justify-content-center" style="position: absolute;top: 50%;transform: translate(0, -50%);">
        <ProgressSpinner></ProgressSpinner>
      </div>
    </div>
    <div v-if="!loading && error === null">
      <div class="grid">
        <div class="col-12 lg:col-4">
          <Borrower :borrower="borrower"></Borrower>
        </div>
        <div class="col-12 lg:col-8">
          <EventsTable
            :title="borrower.surname + ' ' + borrower.name + ' ' + borrower.patronymic + ': события по займам'"
            :default-data="{borrowerUuid: borrower.uuid}"
            :ignored-fields="['fio']"
          ></EventsTable>
        </div>
        <div class="col-12 lg:col-6">
          <BorrowerApplicationsTable :borrower-uuid="borrower.uuid"></BorrowerApplicationsTable>
        </div>
      </div>
    </div>
    <div v-if="!loading && error !== null">
      <Error :message="error"></Error>
    </div>
  </div>
</template>

<script>
import FunctionsService from "@/service/FunctionsService";
import ResponseService from "@/service/ResponseService";
import Borrower from "@/components/Event/Borrower";
import CompendiumService from "@/service/CompendiumService";
import EventsTable from "@/components/tables/EventsTable";
import BorrowerApplicationsTable from "@/components/tables/BorrowerApplicationsTable";
import Error from "@/components/Error";

export default {
  name: "BorrowerView",
  components: {Error, BorrowerApplicationsTable, EventsTable, Borrower},
  data() {
    return {
      compendiumService: CompendiumService,
      loading: true,
      error: null,
      borrower: null,
    }
  },
  methods: {
    getBorrower() {
      this.loading = true
      FunctionsService.getBorrower(this.$router.currentRoute.value.params.uuid).then((res) => {
        this.borrower = res
        this.loading = false
      }).catch((err) => {
        ResponseService.handleErrorResponseWithoutForm(err, this.$toast)
        this.error = 'Произошла ошибка'
        this.loading = false
      })
    }
  },
  mounted() {
    this.getBorrower()
  }
}
</script>

<style scoped>

</style>