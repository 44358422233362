<template>
  <div>
    <Card>
      <template #title>Заявки заёмщика</template>
      <template #content>
        <AbstractTable code="borrowerApplications" :default-data="{borrowerUuid: borrowerUuid}" display-filter="menu"></AbstractTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
export default {
  name: "BorrowerApplicationsTable",
  components: {AbstractTable},
  props: {
    borrowerUuid: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>